import React from "react"
import styled from "styled-components"
import { typography, textStyle } from "styled-system"

import Box from "./Box"

const Text = styled(Box)(typography, textStyle)

const TEXT_TYPES = {
  H1: props => <Text textStyle={"h1"} {...props} />,
  H2: props => <Text textStyle={"h2"} {...props} />,
  H3: props => <Text textStyle={"h3"} {...props} />,
  H4: props => <Text textStyle={"h4"} {...props} />,
  H5: props => <Text textStyle={"h5"} {...props} />,
  H6: props => <Text textStyle={"h6"} {...props} />,
  Subtitle1: props => <Text textStyle={"subtitle1"} {...props} />,
  Subtitle2: props => <Text textStyle={"subtitle2"} {...props} />,
  Body1: props => <Text textStyle={"body1"} {...props} />,
  Body2: props => <Text textStyle={"body2"} {...props} />,
  Caption: props => <Text textStyle={"caption"} {...props} />,
}

Object.keys(TEXT_TYPES).forEach(type => {
  Text[type] = TEXT_TYPES[type]
  Text[type].displayName = type
})

Text.TEXT_TYPES = TEXT_TYPES

Text.defaultProps = {
  fontFamily: "sans",
  color: "text",
  // letterSpacing: '1px'
}

export { Text, TEXT_TYPES }
export default Text
