import React from "react"

import { Flex } from "../Base"

const AppContainer = props => {
  return (
    <Flex
      flexDirection={"column"}
      px={[3, 4]}
      py={6}
      maxWidth={"1024px"}
      width={"100%"}
      {...props}
    />
  )
}

export default AppContainer
