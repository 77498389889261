const sizes = [12, 14, 16, 18, 20, 24, 28, 32, 48, 64, 96]
const fontSizes = sizes.map(s => `${s}px`)

const fontWeights = {
  light: 300,
  normal: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
}

const REBOOT_FONT_FAMILY = `-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
    'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji';`

const fontFamily = {
  // sans: `sans-serif, ${REBOOT_FONT_FAMILY}`,
  sans: `'Work Sans', sans-serif, ${REBOOT_FONT_FAMILY}`,
}

const fonts = {}
fonts.fontSizes = fontSizes
fonts.fontWeights = fontWeights
fonts.fontFamily = fontFamily

export { fontSizes, fontWeights, fontFamily }
export default fonts
