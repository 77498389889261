import React from "react"
import { Helmet } from "react-helmet"

import { Flex } from "../Base"
import ThemeProvider from "../Theme/ThemeProvider"
import NavBar from "./NavBar"
import AppContainer from "./AppContainer"

const AppLayout = props => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Tadpol Rachatasumrit - Software Engineer | Designer | Architect
        </title>
        <meta
          name="description"
          content="A software engineer with the design background as a designer and
          architect. I enjoy both coding and design, but it is Better Together."
        ></meta>
      </Helmet>
      <ThemeProvider>
        <NavBar />
        <Flex width={"100vw"} maxWidth={"100vw"} justifyContent={"center"}>
          <AppContainer {...props} />
        </Flex>
      </ThemeProvider>
    </>
  )
}

export default AppLayout
