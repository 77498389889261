import { fontSizes, fontWeights } from './fonts';

const textStyles = {
  h1: {
    fontSize: fontSizes[10],
    fontWeight: fontWeights.normal,
    letterSpacing: -1.5,
    lineHeight: '90px',
  },
  h2: {
    fontSize: fontSizes[9],
    fontWeight: fontWeights.normal,
    letterSpacing: -0.5,
    lineHeight: '65px',
  },
  h3: {
    fontSize: fontSizes[8],
    fontWeight: fontWeights.normal,
    letterSpacing: -0.35,
    lineHeight: '50px',
  },
  h4: {
    fontSize: fontSizes[7],
    fontWeight: fontWeights.medium,
    letterSpacing: -0.25,
    lineHeight: '40px',
  },
  h5: {
    fontSize: fontSizes[5],
    fontWeight: fontWeights.semibold,
    letterSpacing: 0,
    lineHeight: '26px',
  },
  h6: {
    fontSize: fontSizes[4],
    fontWeight: fontWeights.semibold,
    letterSpacing: 0,
    lineHeight: '22px',
  },
  subtitle1: {
    fontSize: fontSizes[3],
    fontWeight: fontWeights.semibold,
    letterSpacing: 0.1,
    lineHeight: '20px',
  },
  subtitle2: {
    fontSize: fontSizes[3],
    fontWeight: fontWeights.medium,
    letterSpacing: 0.1,
    lineHeight: '20px',
  },
  body1: {
    fontSize: fontSizes[2],
    fontWeight: fontWeights.normal,
    letterSpacing: 0.1,
    lineHeight: '24px',
  },
  body2: {
    fontSize: fontSizes[1],
    fontWeight: fontWeights.light,
    letterSpacing: 0.2,
    lineHeight: '18px',
  },
  caption: {
    fontSize: fontSizes[0],
    fontWeight: fontWeights.light,
    letterSpacing: 0.5,
    lineHeight: '14px',
  },
  hero: {
    fontSize: fontSizes[8],
    fontWeight: fontWeights.bold,
    letterSpacing: -0.35,
    lineHeight: '50px',
  },
  heroSubtitle: {
    fontSize: fontSizes[7],
    fontWeight: fontWeights.light,
    letterSpacing: -0.25,
    lineHeight: '40px',
  },
};

export default textStyles;
