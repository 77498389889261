import styled from "styled-components"
import { typography, border, buttonStyle } from "styled-system"
import ssPropTypes from "@styled-system/prop-types"

import Box from "./Box"

const Button = styled(Box)(
  typography,
  border,

  buttonStyle

  // rebass moved theme here TODO: why?
  // themed('Button'),
)

Button.propTypes = {
  ...ssPropTypes.typography,
  ...ssPropTypes.border,
  ...ssPropTypes.buttonStyle,
}

Button.defaultProps = {
  as: "button",
}

Button.displayName = "Button"

export default Button
