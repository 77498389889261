import { fontSizes, fontWeights, fontFamily } from "./fonts"
import textStyles from "./textStyles"
import spaces from "./spaces"
import colors from "./colors"
import buttonStyles from "./buttonStyles"

const theme = {
  breakpoints: ["512px", "769px", "1025px"],
  fontSizes,
  fontWeights,
  fonts: fontFamily,
  space: spaces,
  colors,
  textStyles,
  Card: {},
  Button: {
    outline: "none",
    borderRadius: "50px",
    fontFamily: fontFamily.sans,
    fontSize: "16px",
    padding: "8px 20px",
    cursor: "pointer",
    "&[disabled]": {
      backgroundColor: "#ededed",
      borderColor: "#ededed",
      color: "white",
      cursor: "default",
      "&:hover": {
        backgroundColor: "#ededed",
        borderColor: "#ededed",
        color: "white",
      },
      "&:active": {
        backgroundColor: "#ededed",
        borderColor: "#ededed",
        color: "white",
      },
    },
  },
  buttons: buttonStyles,
}

export default theme
