import { darken, lighten } from "polished"

/**
 * greyscale from 1 - 10
 * 10%: #E5E5E5
 */

const greyscale = {
  grey1: "#1A1A1A",
  grey2: "#333333",
  grey3: "#4D4D4D",
  grey4: "#666666",
  grey5: "#7F7F7F",
  grey6: "#999999",
  grey7: "#BBBBBB",
  grey8: "#CCCCCC",
  grey9: "#E5E5E5",
}

// Add more scale to keyColors here
const scale = [
  { suffix: "light", multiplier: 0.1, func: lighten },
  { suffix: "lighter", multiplier: 0.2, func: lighten },
  { suffix: "dark", multiplier: 0.1, func: darken },
  { suffix: "darker", multiplier: 0.2, func: darken },
]

const keyColors = {
  primary: "#fbaa19",
  secondary: "#4c60f8",
  success: "#009688",
  danger: "#ef6543",
  text: "#3B3B3B",
}

// Add scales to keyColors
Object.keys(keyColors).forEach(color => {
  scale.forEach(({ suffix, multiplier, func }) => {
    keyColors[`${color}${suffix}`] = func(multiplier, keyColors[color])
  })
})

// colors that are very specific to some parts/components in the app such as navbar, app, ...
const customColors = {
  APP_BACKGROUND: "white",
  APP_BACKGROUND_GREY: "rgba(242, 242, 242, 0.5)",
}

const colors = {
  white: "white",
  black: "black",
  greyscale: greyscale,
  disabled: "#ededed",
  facebook: "#3b5998",
  ...keyColors,
  ...customColors,
}

export default colors
