import styled from "styled-components"
import { background, border } from "styled-system"
import ssPropTypes from "@styled-system/prop-types"

import Box from "./Box"

const Card = styled(Box)(background, border)

Card.propType = {
  ...ssPropTypes.background,
  ...ssPropTypes.border,
}

Card.displayName = "Card"

export default Card
