import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import { Box, Flex, Text, Image } from "../Base"
const NavItem = styled(({ to, ...props }) => (
  <Box mx={3} py={3}>
    <Link
      to={to}
      style={{ display: "block", border: "none", textDecoration: "none" }}
      activeStyle={{ borderBottom: "5px solid #fbaa19" }}
    >
      <Text fontSize={5} {...props} />
    </Link>
  </Box>
))``

const NavBar = () => {
  return (
    <Flex
      justifyContent={"center"}
      width={"100vw"}
      position={"fixed"}
      as={"nav"}
      background={"white"}
    >
      <Flex
        justifyContent={"flex-end"}
        width={"100%"}
        maxWidth={"1024px"}
        px={4}
        pt={4}
      >
        <Box mr={"auto"}>
          <Link
            to={"/"}
            style={{ display: "block", border: "none", textDecoration: "none" }}
          >
            <Image src={"/assets/logo.svg"} height={"36px"} />
          </Link>
        </Box>
        <NavItem to={"/work/"}>Work</NavItem>
        {/* <NavItem to={"/about/"}>About</NavItem> */}
      </Flex>
    </Flex>
  )
}

export default NavBar
