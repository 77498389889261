import { darken, transparentize } from 'polished';

import colors from './colors';

const BUTTON_TYPES = {
  DEFAULT: 'DEFAULT',
  OUTLINED: 'OUTLINED',
  FLAT: 'FLAT',
};

const getFlatButtonVariant = color => {
  return {
    color: color,
    backgroundColor: 'transparent',
    border: 'none',

    '&:hover': {
      color: darken(0.1, color),
    },
    '&:active': {
      color: darken(0.35, color),
    },
  };
};

const getOutlinedButtonVariant = color => {
  return {
    color: color,
    backgroundColor: 'white',
    border: `2px solid ${color}`,
    '&:hover': {
      color: darken(0.05, color),
      borderColor: darken(0.05, color),
    },
    '&:active': {
      color: darken(0.15, color),
      borderColor: darken(0.15, color),
    },
  };
};

const getDefaultButtonVariant = color => {
  return {
    color: 'white',
    backgroundColor: color,
    border: `2px solid ${color}`,
    '&:hover': {
      backgroundColor: darken(0.05, color),
      borderColor: darken(0.05, color),
    },
    '&:active': {
      backgroundColor: darken(0.15, color),
      borderColor: darken(0.15, color),
    },
  };
};

const getButtonVariant = (variant, color) => {
  switch (variant) {
    case BUTTON_TYPES.FLAT:
      return getFlatButtonVariant(color);
    case BUTTON_TYPES.OUTLINED:
      return getOutlinedButtonVariant(color);
    case BUTTON_TYPES.DEFAULT:
    default:
      return getDefaultButtonVariant(color);
  }
};

const buttonStyles = {
  primary: getButtonVariant(BUTTON_TYPES.DEFAULT, colors.primary),
  primaryOutlined: getButtonVariant(BUTTON_TYPES.OUTLINED, colors.primary),
  primaryFlat: getButtonVariant(BUTTON_TYPES.FLAT, colors.primary),
  secondary: getButtonVariant(BUTTON_TYPES.DEFAULT, colors.secondary),
  secondaryOutlined: getButtonVariant(BUTTON_TYPES.OUTLINED, colors.secondary),
  secondaryFlat: getButtonVariant(BUTTON_TYPES.FLAT, colors.secondary),
  facebook: getButtonVariant(BUTTON_TYPES.DEFAULT, colors.facebook),
  facebookOutlined: getButtonVariant(BUTTON_TYPES.OUTLINED, colors.facebook),
  facebookFlat: getButtonVariant(BUTTON_TYPES.FLAT, colors.facebook),
  grey: getButtonVariant(BUTTON_TYPES.DEFAULT, colors.greyscale.grey2),
  greyOutlined: getButtonVariant(BUTTON_TYPES.OUTLINED, colors.greyscale.grey2),
  lightGreyFlat: getButtonVariant(BUTTON_TYPES.FLAT, colors.greyscale.grey3),
  white: getButtonVariant(BUTTON_TYPES.DEFAULT, colors.white),
};

export default buttonStyles;
