import styled from "styled-components"
import { borderRadius } from "styled-system"
import ssPropTypes from "@styled-system/prop-types"

import Box from "./Box"

const Image = styled(Box)(
  {
    maxWidth: "100%",
  },
  borderRadius
)

Image.propTypes = {
  ...ssPropTypes.borderRadius,
}

Image.defaultProps = {
  as: "img",
  height: "auto",
}

Image.displayName = "Image"

export default Image
