import styled from "styled-components"
import {
  space,
  color,
  layout,
  flexbox,
  border,
  background,
  position,
} from "styled-system"
import ssPropTypes from "@styled-system/prop-types"

const Box = styled.div(
  {
    boxSizing: "border-box",
    minWidth: 0,
  },
  space,
  color,
  layout,
  flexbox,
  border,
  background,
  position
)

Box.propTypes = {
  ...ssPropTypes.space,
  ...ssPropTypes.color,
  ...ssPropTypes.layout,
  ...ssPropTypes.flexbox,
}

Box.displayName = "Box"

export default Box
