export const WORK_CATEGORY = {
  DEV: "DEV",
  ARCH: "ARCH",
}

export const WORK_ID = {
  BETA_CONFERENCE: "beta-conference",
  GENERATIVE_COVER: "generative-cover",
  FLASHCARD: "flashcard",
  SKILL_SCORE: "skill-score",
  TRAT: "trat-residence",
  SHOWFLAT: "168-showflat",
  ART4D: "art4d-pavillion",
  KUMAMOTO: "kumamoto-artpolis",
  TCDC: "tcdc-khon-kaen",
  ANYWHERE: "learn-anywhere",
  SKOOLDIO: "skooldio-web",
  ADVANCE: "advance-architecture",
  MARCHMELLOW: "march-mellow",
}

export const WORK = [
  {
    key: "skill-score",
    isSelectedWork: true,
    active: true,
    name: "Skill Score",
    url: "/work/skill-score",
    role: "software engineer",
    category: WORK_CATEGORY.DEV,
    data: {
      sourceUrl: "https://skillscore.skooldio.com",
    },
  },
  {
    key: "beta-conference",
    isSelectedWork: true,
    active: true,
    name: "Beta Challenge Game",
    url: "/work/beta-conference",
    role: "software engineer",
    category: WORK_CATEGORY.DEV,
    data: { sourceUrl: "" },
  },
  {
    key: "generative-cover",
    isSelectedWork: true,
    active: true,
    name: "Generative Cover",
    url: "/work/generative-cover",
    role: "software engineer | designer",
    category: WORK_CATEGORY.DEV,
  },
  {
    key: "flashcard",
    isSelectedWork: false,
    active: false,
    name: "Flashcard",
    url: "/work/flashcard",
    role: "software engineer | designer",
    category: WORK_CATEGORY.DEV,
  },
  {
    key: "learn-anywhere",
    isSelectedWork: false,
    active: false,
    name: "LearnAnywhere",
    url: "/work/learn-anywhere",
    role: "software engineer",
    category: WORK_CATEGORY.DEV,
  },
  {
    key: "skooldio-web",
    isSelectedWork: false,
    active: false,
    name: "Skooldio Web",
    url: "/work/skooldio-web",
    role: "software engineer",
    category: WORK_CATEGORY.DEV,
  },
  {
    key: "march-mellow",
    isSelectedWork: false,
    active: false,
    name: "Marchmellow",
    url: "/work/march-mellow",
    role: "software engineer | designer",
    category: WORK_CATEGORY.DEV,
  },
  {
    key: "art4d-pavillion",
    isSelectedWork: true,
    active: true,
    name: "Proto-Tectonic",
    url: "/work/art4d-pavillion",
    role: "architect",
    category: WORK_CATEGORY.ARCH,
  },
  {
    key: "trat-residence",
    isSelectedWork: true,
    active: true,
    name: "Trat Residence",
    url: "/work/trat-residence",
    role: "architect",
    category: WORK_CATEGORY.ARCH,
  },
  {
    key: "kumamoto-artpolis",
    isSelectedWork: true,
    active: true,
    name: "Kumamoto Artpolis",
    url: "/work/kumamoto-artpolis",
    role: "architect",
    category: WORK_CATEGORY.ARCH,
  },
  {
    key: "168-showflat",
    isSelectedWork: true,
    active: true,
    name: "168 Sales Gellery",
    url: "/work/168-showflat",
    role: "architect",
    category: WORK_CATEGORY.ARCH,
  },
  {
    key: "tcdc-khon-kaen",
    isSelectedWork: false,
    active: false,
    name: "TCDC Khon Kaen",
    url: "/work/tcdc-khon-kaen",
    role: "architect",
    category: WORK_CATEGORY.ARCH,
  },
  {
    key: "advance-architecture",
    isSelectedWork: false,
    active: false,
    name: "Performative Assemble in Vernacular Architecture",
    url: "/work/advance-architecture",
    role: "architect",
    category: WORK_CATEGORY.ARCH,
  },
]
