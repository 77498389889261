import React from "react"
import PropTypes from "prop-types"
import { ThemeProvider as StyledThemeProvider } from "styled-components"

import theme from "./index"
const ThemeProvider = ({ ...props }) => {
  return <StyledThemeProvider theme={theme} {...props} />
}

ThemeProvider.propTypes = {
  theme: PropTypes.object,
}

export default ThemeProvider
