const customSpaces = {
  NAVBAR_HEIGHT: 80,
  FOOTER_HEIGHT: 72,
}

const spaces = [0, 4, 8, 16, 32, 64, 128, 256, 512]
const spacesWithUnit = spaces.map(s => `${s}px`)

Object.keys(customSpaces).forEach(key => {
  const value = customSpaces[key]

  spaces[key] = value
  spacesWithUnit[key] = typeof value === "number" ? `${value}px` : value
})

export { spaces as spacesWithoutUnit, spacesWithUnit }
export default spacesWithUnit
